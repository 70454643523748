import axios from 'axios'

const VERSION_1 = 'v1';
const VERSION_2 = 'v2';

const getHeaders = (key) => {
  return {
    'Content-Type': 'application/json',
    Authorization: `Basic ${Buffer.from(`${key}:`).toString('base64')}`,
  };
};

const getAppKeyHeaders = () => {
  return {
    'Content-Type': 'application/json',
    'x-api-key': process.env.NEXT_PUBLIC_API_KEY,
  };
};

const getMerchant = async (secretKey) => {
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_API_URL}/v2/merchant`,
      { headers: getHeaders(secretKey) }
    );
    return response;
  } catch (err) {
    if (err.response) {
      return err.response;
    }
    return null;
  }
}

const createToken = async (publicKey, name, number, expMonth, expYear, cvc, country, billingAddress) => {
  try {
    let params = {
      name,
      number,
      exp_month: expMonth,
      exp_year: expYear,
      cvc,
      address_country: country,
    };
    if (billingAddress) {
      params = {
        ...params,
        address_line1: billingAddress.line_1,
        address_line2: billingAddress.suburb ? `${billingAddress.line_2} ${billingAddress.suburb}` : billingAddress.line_2,
        address_city: billingAddress.city ? billingAddress.city : null,
        address_state: billingAddress.state ? billingAddress.state : null,
        address_zip: billingAddress.postal_code ? billingAddress.postal_code : null
      }
    }

    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/${VERSION_1}/card/tokens`,
      {
        card: params
      },
      { headers: getHeaders(publicKey) }
    );
    return response;
  } catch (err) {
    if (err.response) {
      return err.response;
    }
    return null;
  }
}

const createCustomer = async (secretKey, version, email, name, mobile_number, description) => {
  try {
    const ver = version === '2' ? VERSION_2 : VERSION_1;

    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/${ver}/customers`,
      {
        email,
        name,
        mobile_number,
        description,
      },
      { headers: getHeaders(secretKey) }
    );
    return response;
  } catch (err) {
    if (err.response) {
      return err.response;
    }
    return null;
  }
}

const getCustomer = async (secretKey, version, customer) => {
  try {
    const ver = version === '2' ? VERSION_2 : VERSION_1;

    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_API_URL}/${ver}/customers/${customer}`,
      { headers: getHeaders(secretKey) }
    );
    return response;
  } catch (err) {
    if (err.response) {
      return err.response;
    }
    return null;
  }
}

const getCustomerByEmail = async (secretKey, email) => {
  try {

    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_API_URL}/v2/customers/by_email/${email}`,
      { headers: getHeaders(secretKey) }
    );
    return response;
  } catch (err) {
    if (err.response) {
      return err.response;
    }
    return null;
  }
}

const updateCustomer = async (secretKey, version, customer, mobileNumber) => {
  try {
    const ver = version === '2' ? VERSION_2 : VERSION_1;

    const response = await axios.put(
      `${process.env.NEXT_PUBLIC_API_URL}/${ver}/customers/${customer}`,
      {
        mobile_number: mobileNumber.replace('+', ''),
      },
      { headers: getHeaders(secretKey) }
    );
    return response;
  } catch (err) {
    if (err.response) {
      return err.response;
    }
    return null;
  }
}

const attachCustomerSource = async (secretKey, customer, source) => {
  try {

    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/v2/customers/${customer}/sources`,
      {
        source,
      },
      { headers: getHeaders(secretKey) }
    );
    return response;
  } catch (err) {
    if (err.response) {
      return err.response;
    }
    return null;
  }
}

const removeCustomerSource = async (secretKey, customer, source) => {
  try {

    const response = await axios.delete(
      `${process.env.NEXT_PUBLIC_API_URL}/v2/customers/${customer}/sources/${source}`,
      { headers: getHeaders(secretKey) }
    );
    return response;
  } catch (err) {
    if (err.response) {
      return err.response;
    }
    return null;
  }
}

const createCharge = async (secretKey, version, amount, currency, source, description, statement_descriptor, capture, gateway, metadata, require_auth) => {
  try {

    const ver = version === '2' ? VERSION_2 : VERSION_1;
    if (version === '2') {
      const response = await axios.post(
        `${process.env.NEXT_PUBLIC_API_URL}/${ver}/charges`,
        {
          amount,
          currency,
          source,
          description,
          statement_descriptor,
          capture,
          metadata,
          require_auth,
        },
        { headers: getHeaders(secretKey) }
      );
      return response;
    } else {
      const response = await axios.post(
        `${process.env.NEXT_PUBLIC_API_URL}/${ver}/charges`,
        {
          amount,
          currency,
          source,
          description,
          statement_descriptor,
          capture,
          gateway
        },
        { headers: getHeaders(secretKey) }
      );
      return response;
    }
    
  } catch (err) {
    if (err.response) {
      return err.response;
    }
    return null;
  }
}

const create3dsCharge = async (secretKey, amount, currency, source, description, statement_descriptor, capture, gateway, redirect) => {
  try {

    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/${VERSION_1}/charges`,
      {
        amount,
        currency,
        source,
        description,
        statement_descriptor,
        capture,
        gateway,
        callback_url: redirect,
        redirect_url: redirect
      },
      { headers: getHeaders(secretKey) }
    );
    return response;
  } catch (err) {
    if (err.response) {
      return err.response;
    }
    return null;
  }
}

const createSource = async (publicKey, version, amount, currency, type, redirect, card = null, owner = null) => {
  try {

    const ver = version === '2' ? VERSION_2 : VERSION_1;
    let params = {
      type,
      redirect: {
        success: redirect.successUrl,
        fail: redirect.failUrl,
        notify: redirect.notifyUrl ? redirect.notifyUrl : null,
      }
    };

    if (version === '2') {
      if (card && type === 'card') {
        params = {
          ...params,
          card
        }
      }

      if (owner) {
        params = {
          ...params,
          owner
        }
      }
    } else {
      params = {
        ...params,
        amount,
        currency
      }
    }

    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/${ver}/wallet/sources`,
      params,
      { headers: getHeaders(publicKey) }
    );
    return response;
  } catch (err) {
    if (err.response) {
      return err.response;
    }
    return null;
  }
}

const getCharge = async (secretKey, version, chargeId) => {
  try {
    const ver = version === '2' ? VERSION_2 : VERSION_1;

    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_API_URL}/${ver}/charges/${chargeId}`,
      { headers: getHeaders(secretKey) }
    );
    return response;
  } catch (err) {
    if (err.response) {
      return err.response;
    }
    return null;
  }
}

const refundCharge = async (secretKey, chargeId, amount) => {
  try {
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/v2/charges/${chargeId}/refund`,
      {
        amount
      },
      { headers: getHeaders(secretKey) }
    );
    return response;
  } catch (err) {
    if (err.response) {
      return err.response;
    }
    return null;
  }
}

const voidCharge = async (secretKey, chargeId) => {
  try {
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/v2/charges/${chargeId}/void`,
      {},
      { headers: getHeaders(secretKey) }
    );
    return response;
  } catch (err) {
    if (err.response) {
      return err.response;
    }
    return null;
  }
}

const createBankLogin = async (secretKey, version, code, customer, redirect) => {
  try {
    const ver = version === '2' ? VERSION_2 : VERSION_1;

    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/${ver}/bank/login`,
      {
        code,
        customer,
        redirect
      },
      { headers: getHeaders(secretKey) }
    );
    return response;
  } catch (err) {
    if (err.response) {
      return err.response;
    }
    return null;
  }
}

const getBankAccounts = async (secretKey, customer, code) => {
  try {

    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_API_URL}/${VERSION_1}/bank/accounts?customer=${customer}&code=${code}`,
      { headers: getHeaders(secretKey) }
    );
    return response;
  } catch (err) {
    if (err.response) {
      return err.response;
    }
    return null;
  }
}

const getCustomerMsisdn = async (secretKey, source) => {
  try {

    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_API_URL}/${VERSION_1}/bank/msisdn?source=${source}`,
      { headers: getHeaders(secretKey) }
    );
    return response;
  } catch (err) {
    if (err.response) {
      return err.response;
    }
    return null;
  }
}

const createBankCharge = async (secretKey, version, amount, currency, source, description, statement_descriptor, capture, redirect_url, checkout_url) => {
  try {
    const ver = version === '2' ? VERSION_2 : VERSION_1;
    let params = {
      amount,
      currency,
      source,
      description,
      statement_descriptor,
      capture,
      redirect_url
    };

    if (version !== '2') {
      params = {...params, checkout_url};
    }


    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/${ver}/bank/charges`,
      params,
      { headers: getHeaders(secretKey) }
    );
    return response;
  } catch (err) {
    if (err.response) {
      return err.response;
    }
    return null;
  }
}

const verifyBankCharge = async (secretKey, version, chargeId, otp, confirmation_id) => {
  try {
    const ver = version === '2' ? VERSION_2 : VERSION_1;

    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/${ver}/bank/verify`,
      {
        chargeId,
        otp,
        confirmation_id
      },
      { headers: getHeaders(secretKey) }
    );
    return response;
  } catch (err) {
    if (err.response) {
      return err.response;
    }
    return null;
  }
}

const updateSession = async (secretKey, version, sessionId, customer, charge, shipping, billing) => {
  try {
    const ver = version === '2' ? VERSION_2 : VERSION_1;

    const response = await axios.put(
      `${process.env.NEXT_PUBLIC_API_URL}/${ver}/sessions/${sessionId}`,
      {
        customer,
        charge,
        shipping,
        billing
      },
      { headers: getHeaders(secretKey) }
    );
    return response;
  } catch (err) {
    if (err.response) {
      return err.response;
    }
    return null;
  }
}

const updateSessionPayment = async (secretKey, version, sessionId, customer, charge, shipping, billing) => {
  try {
    const ver = version === '2' ? VERSION_2 : VERSION_1;

    const response = await axios.put(
      `${process.env.NEXT_PUBLIC_API_URL}/${ver}/sessions/${sessionId}/update_payment`,
      {
        customer,
        charge,
        shipping,
        billing
      },
      { headers: getHeaders(secretKey) }
    );
    return response;
  } catch (err) {
    if (err.response) {
      return err.response;
    }
    return null;
  }
}

const cancelSession = async (secretKey, version, sessionId) => {
  try {
    const ver = version === '2' ? VERSION_2 : VERSION_1;

    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/${ver}/sessions/${sessionId}/cancel`,
      {},
      { headers: getHeaders(secretKey) }
    );
    return response;
  } catch (err) {
    if (err.response) {
      return err.response;
    }
    return null;
  }
}

const requestOtp = async (mobileNumber) => {
  try {

    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/v2/otp/request`,
      {
        mobile_number: mobileNumber.replace('+', ''),
      },
      { headers: getAppKeyHeaders() }
    );
    return response;
  } catch (err) {
    if (err.response) {
      return err.response;
    }
    return null;
  }
}

const verifyOtp = async (messageId, otp) => {
  try {

    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/v2/otp/${messageId}/verify`,
      {
        otp,
      },
      { headers: getAppKeyHeaders() }
    );
    return response;
  } catch (err) {
    if (err.response) {
      return err.response;
    }
    return null;
  }
}


export default {
  getMerchant,
  createToken,
  createCustomer,
  getCustomer,
  getCustomerByEmail,
  updateCustomer,
  attachCustomerSource,
  removeCustomerSource,
  createCharge,
  create3dsCharge,
  createSource,
  getCharge,
  refundCharge,
  voidCharge,
  createBankLogin,
  getBankAccounts,
  getCustomerMsisdn,
  createBankCharge,
  verifyBankCharge,
  updateSessionPayment,
  updateSession,
  cancelSession,
  requestOtp,
  verifyOtp,
}